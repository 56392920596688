import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {Helmet} from 'react-helmet'
import Layout from "../components/layout"
import { Trail, animated } from 'react-spring'
import '../assets/interviews.scss'


const Interview = (props) => {
  let post = props.data.markdownRemark;
  // console.log(post.frontmatter);
  return (
    <Layout>
      <Helmet>

      </Helmet>
      <div className='single-interview'>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4 interviewee-info">
              <Img fluid={post.frontmatter.headshot.childImageSharp.fluid} alt={post.title}/>
              <div className="info-details">
                <h1>{post.frontmatter.title}</h1>
                <h2>{post.frontmatter.role}</h2>
                <h4>{post.frontmatter.date}</h4>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 ">
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>

  )
}

export default Interview;

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata{
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        role
        subTitle
        metaTitle
        date
        metaImage {
          publicURL
          childImageSharp{
            fluid(maxWidth : 1000){
              ...GatsbyImageSharpFluid_tracedSVG
            }
            resize(width: 2000, height: 2000, cropFocus: CENTER){
              src
            }
          }

        }
        headshot {
          childImageSharp{
            fluid(maxWidth : 1000, quality: 100){
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fluid(maxWidth : 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            resize(width: 2000, height: 2000, cropFocus: CENTER){
              src
            }
          }
          publicURL
        }
      }
    }
  }
`